import React, { useState, useEffect } from 'react';
import * as Icon from "@heroicons/react/outline"
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { getDialogsDashboard } from '../../../api/dashboards';
import { getInProgressReportForDialogs } from "../../../api/dialogValues";
import { useToastAction } from '../../../hooks/useToastAction';
import './styles.scss';
import TemplateDialog from '../templateGallery';
import WelcomePage from './welcomePage';
import * as Constants from '../../../utils/constants';
import { useFeature } from '../../../hooks/useFeature';
import { SkeletonLoader } from '../../../components/Table';
import CardBigSmallLabel from '../../../components/CardBigSmallLabelWithSkeletonLoader';
import { formatWithMonthName } from '../../../utils/date';
import { DocumentItemCard, FormItemCard, HighlightCard, HighlightCardVariants, SparkleCard, StudioItemCard } from '@metaforcelabs/metaforce-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileContract } from '@fortawesome/free-solid-svg-icons';
import { faMessagesQuestion } from '@fortawesome/pro-regular-svg-icons';
import { faRotate } from '@fortawesome/pro-light-svg-icons';
import ImportDialog from '../importDialog';
import { jsonTemplates } from '../../../templates/dialogs/jsonTemplates';

export default function Dashboard() {
  const feature = useFeature();
  const dashboardAction = useToastAction();
  const loadProgressAction = useToastAction();
  const [dashboardData, setDashboardData] = useState({ topDialogDefinitions: [{}, {}, {}, {}, {}] });
  const [dialogsProgres, setdialogsProgres] = useState([])
  const [showTemplateDialog, setShowTemplateDialog] = useState(false);
  const [showImportTemplate, setShowImportTemplate] = useState(false);
  const [templateToImport, setTemplateToImport] = useState();
  const [hasData, setHasData] = useState(true);
  const [isLoadingDashboardData, setIsLoadingDashboardData] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    dashboardAction.execute(async () => {
      try {
        const results = await getDialogsDashboard();
        const yieldedResult = results?.topDialogDefinitions.filter(x => !!x.id)?.length > 0;
        setDashboardData(results);
        setHasData(yieldedResult);
      }
      finally {
        setIsLoadingDashboardData(false)
      }
    }, "Failed to load dashboard")
  }

  // useEffect(() => {
  //   const yieldedResult = dashboardData?.topDialogDefinitions.filter(x => !!x.id)?.length > 0;
  //   if (yieldedResult) {
  //     loadDialogProgress(dashboardData);
  //   }
  // }, [dashboardData])

  // const loadDialogProgress = (results) => {
  //   loadProgressAction.execute(async () => {
  //     const dialogDefinitionIds = results?.topDialogDefinitions.map(x => x.id).filter(x => !!x);
  //     const dialogProgressReport = await getInProgressReportForDialogs(dialogDefinitionIds);
  //     setdialogsProgres(dialogProgressReport);
  //   })
  // }

  // const roundNumbers = (number) => {
  //   return Math.round((number + Number.EPSILON) * 100) || 0;
  // }

  const handleViewProcess = () => {
    navigate("/admin/dashboard")
  }

  const sparkleTemplates = [
    {
      id: 1,
      name: "Sparkle",
      description: "This form is designed for new customers to verify and provide essential information. First, you'll need to check and enter your contact details to ensure we can easily reach you. Next, please confirm and provide accurate information about your company to help us keep our records up-to-date.",
      template: jsonTemplates.templateA,
      onClick: () => {
        setShowImportTemplate(true);
        setTemplateToImport(sparkleTemplates[0])
      }
    },
    {
      id: 2,
      name: "Sparkle",
      description: "This smartform is a two step process which starts with 1) Vendor filling in information and signing 2) then customer is filling in information and sign. Go to the Process tab in Studio to control the process.",
      template: jsonTemplates.templateB,
      onClick: () => {
        setShowImportTemplate(true);
        setTemplateToImport(sparkleTemplates[1])
      }
    },
    {
      id: 3,
      name: "Sparkle",
      description: "This form is designed to help new customers undersand how you add multiple sections with different objects such as input fields, multiple selct and information fields such as text, images and video. It's a great starting point for new users.",
      template: jsonTemplates.templateC,
      onClick: () => {
        setShowImportTemplate(true);
        setTemplateToImport(sparkleTemplates[2])
      }
    }
  ]

    return (
      <>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-10">
          <div className='border-b border-gray-200 flex justify-between items-center pb-5'>
            <h1 className="text-3xl font-bold">Studio</h1>
          </div>

        </div>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
          <div className='mt-5'>
            <dl className=" mr-1 grid grid-cols-2 gap-2 sm:grid-cols-4">
              <FormItemCard
                body={
                  <SkeletonLoader showOverride={dashboardAction.isExecuting}>
                    <div className='text-lg font-bold flex items-center justify-between'>
                      {dashboardData?.smartFormActiveCount} forms
                    </div>
                  </SkeletonLoader>
                }
                onClick={() => navigate("/admin/smartforms")}
                rightHeader={<Icon.ArrowRightIcon className="h-5 w-5 transform -rotate-45" />}
              />
              <FormItemCard
                body={
                  <SkeletonLoader showOverride={dashboardAction.isExecuting}>
                    <div className='text-lg font-bold  flex items-center justify-between'>
                      {dashboardData.smartFormTemplatesCount} form templates
                    </div>
                  </SkeletonLoader>

                }
                onClick={() => navigate("/admin/templates/smartforms")}
                rightHeader={<Icon.ArrowRightIcon className="h-5 w-5 transform -rotate-45" />}
              />
              <FormItemCard
                body={
                  <SkeletonLoader showOverride={dashboardAction.isExecuting}>
                    <div className='text-lg font-bold  flex items-center justify-between'>
                      {dashboardData.smartObjectsCount} form objects
                    </div>
                  </SkeletonLoader>
                }
                onClick={() => navigate("/admin/templates/objects")}
                rightHeader={<Icon.ArrowRightIcon className="h-5 w-5 transform -rotate-45" />}
              />
              <FormItemCard
                body={
                  <div className='text-lg font-bold flex items-center justify-between'>
                    Notification settings
                  </div>
                }
                onClick={() => navigate("/admin/templates/email")}
                rightHeader={<Icon.ArrowRightIcon className="h-5 w-5 transform -rotate-45" />}
              />
            </dl>
          </div>
        </div>
        <div className='max-w-7xl mx-auto py-4 sm:py-6 px-4 sm:px-6 lg:px-8'>
          <HighlightCard
            body={"The simplest wayt to create a new smartform is to start with one of the built-in or custom designs. From there you can add objects and elemets to achieve the disired features. Remember to publish and activate the form to make it publicly available."}
            header={"Create a digital communication form"}
            buttonText={"Create"}
            onClick={() => { setShowTemplateDialog(true) }}
            variant={HighlightCardVariants.yellow}
            rightContent={
              <div className='flex justify-end'>
                <div className='bg-document-yellow-dark flex px-8 py-4 items-center justify-center rounded-full'>
                  <div className='text-document-yellow-dark h-20 w-20 bg-black flex items-center justify-center rounded-full'>
                    <FontAwesomeIcon icon={faFileContract} size={'2x'} />
                  </div>
                </div>
              </div>
            }
          />

        </div>
        <div className='max-w-7xl mx-auto pb-2 sm:pb-4 px-4 sm:px-6 lg:px-8'>
          <div className='grid grid-rows-1 grid-flow-col gap-4 min-w-7xl overflow-x-auto'>
            {
              sparkleTemplates.map((template, index) => {
                return (
                  <div className='h-full w-72 sm:w-80 lg:w-auto flex items-stretch'>
                    <SparkleCard body={template.description} buttonText={"Use this form"} onClick={template.onClick} />
                  </div>
                )
              })
            }
          </div>
        </div>
        <>
          <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between'>
            <h3 className="text-xl leading-6 font-bold text-gray-900">Top forms</h3>
            <Link to={"/admin/smartforms"}
              className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
            >
              See all forms <span aria-hidden="true"> &rarr;</span>
            </Link>
          </div>

          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-5 w-full flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                {
                  !hasData &&
                  <div className="text-center border-2 rounded-md border-gray-300 border-dashed p-5 mt-4">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        vectorEffect="non-scaling-stroke"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                      />
                    </svg>
                    <h3 className="mt-2 text-sm font-medium text-gray-900">No dialog forms created</h3>
                    <p className="mt-1 text-sm text-gray-500">Create a dialog from the list page</p>
                    <div className="mt-6">
                      <NavLink to="/admin/smartForm">
                        <button
                          type="button"
                          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400"
                        >
                          <Icon.PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                          New dialog
                        </button>
                      </NavLink>
                    </div>
                  </div>
                }
                {
                  hasData &&
                  <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Created by
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Created
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Updated by
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Updated
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >

                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {dashboardData?.topDialogDefinitions?.map((topDialogDefinition, topDialogDefinitionIndex) => {
                          return (
                            <tr key={topDialogDefinition.id} className={topDialogDefinitionIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 relative" style={{ minWidth: "250px" }}>
                                <SkeletonLoader element={topDialogDefinition}>
                                  {topDialogDefinition.name}
                                  <span className='block text-gray-500'>
                                    <span>{Math.max(topDialogDefinition.languagesCount, 1)}  translations</span>
                                  </span>
                                </SkeletonLoader>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                <SkeletonLoader showOverride={dashboardAction.isExecuting || loadProgressAction.isExecuting}>
                                  <span>{topDialogDefinition.createdByEmail}</span>
                                </SkeletonLoader>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                <SkeletonLoader element={topDialogDefinition}>
                                  {formatWithMonthName(topDialogDefinition.createdDate)}
                                </SkeletonLoader>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                <SkeletonLoader element={topDialogDefinition}>
                                  {topDialogDefinition.updatedByEmail}
                                </SkeletonLoader>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                <SkeletonLoader element={topDialogDefinition}>
                                  {formatWithMonthName(topDialogDefinition.updatedDate)}
                                </SkeletonLoader>
                              </td>
                              {
                                feature.hasFeature(Constants.designFeatureCode) &&
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                  <SkeletonLoader element={topDialogDefinition}>
                                    <NavLink to={`/admin/dialog/${topDialogDefinition.id}/design`}
                                      className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                                    >
                                      Edit
                                    </NavLink>
                                  </SkeletonLoader>
                                </td>
                              }
                            </tr>
                          )
                        })}
                      </tbody>

                    </table>
                  </div>
                }
              </div>
            </div>
          </div>
        </>
        <div className='max-w-7xl mx-auto py-4 sm:py-6 px-4 sm:px-6 lg:px-8'>
          <HighlightCard
            body={"When Smartforms are activated, they can involve one or more participants. You can monitor the processes and roll back certain smartforms if steps need to be redone."}
            header={"Smartforms in process"}
            buttonText={"View"}
            onClick={() => { handleViewProcess() }}
            variant={HighlightCardVariants.yellow}
            rightContent={
              <div className='flex justify-end'>
                <div className='bg-document-yellow-dark flex px-8 py-4 items-center justify-center rounded-full'>
                  <div className='text-document-yellow-dark bg-black h-20 w-20 rounded-full relative flex items-center justify-center'>
                    <FontAwesomeIcon icon={faRotate} size='3x' className='' />
                    <div className='absolute h-full w-full top-0 right-0 left-0 bottom-0 text-document-yellow-dark flex items-center justify-center'>
                      <FontAwesomeIcon icon={faMessagesQuestion} size={'1x'} className='' />
                    </div>
                  </div>
                </div>
              </div>
            }
          />

        </div>
        <TemplateDialog open={showTemplateDialog} setOpen={setShowTemplateDialog} />
        <ImportDialog open={showImportTemplate} setOpen={setShowImportTemplate} dialogTemplate={templateToImport} />
      </>
    )

}