import { useClassNames } from '@metaforcelabs/metaforce-core';
import { Field, ErrorMessage } from 'formik';

export const FormikCheckbox = ({
  name,
  label,
  secondaryLabel,
  formikProps,
  onBlur,
  onClick,
  type = null,
  placeholder = null,
  required = false,
  styles,
  disabled
}) => {
  const hasError = formikProps?.errors && formikProps.errors[name];
  const errorClasses = 'ring-red-500 border-red-500 focus:ring-red-500 focus:border-red-500'

  const { classNames } = useClassNames();

  const handleBlur = (evt) => {
    if (onBlur) {
      onBlur(evt)
    }
    if (formikProps?.handleBlur) {
      formikProps.handleBlur(evt)
    }
  }

  return (
    <div className="mt-1">
      <Field
        id={name}
        type={type || "checkbox"}
        name={name}
        className={`appearance-none shadow-sm w-5 h-5 rounded-sm checked:bg-brand-blue mr-4 sm:text-sm border-gray-300 focus:ring-gray-400 focus:border-gray-400  ${styles ? styles : ""} ${hasError ? errorClasses : ''}`}
        placeholder={placeholder}
        onBlur={handleBlur}
        onClick={onClick}
        disabled={disabled}
      />

      {
        label &&
        <label htmlFor={name} className={classNames("text-sm font-medium", disabled ? "text-gray-500" : "text-gray-900")}>
          {label}
          {required === true && <span className="text-red-400"> *</span>}
        </label>
      }

      {
        secondaryLabel &&
        <label htmlFor={name} className={classNames("mt-2 flex flex-col text-xs	font-medium", disabled ? "text-gray-400" : "text-gray-700")}>
          {secondaryLabel}
        </label>
      }
      <ErrorMessage name={name} component="div" className="text-red-500" />
    </div>
  )
}